.uppy-DragDrop-inner {
  padding: 20px
}

.uppy-thumbnails {
  .uppy-thumbnail-container {
    height: auto;
    padding: 7px 16px;
    width: 100%;
    margin: 8px 0;
    img {
      width: 118px;
      height: 66px;
      object-fit: contain;
      object-position: center center;
    }
  }
}
