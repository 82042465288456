@import "variables";
@mixin  chapter-list-menu{
  .header-mobile-fixed .header-mobile{
    max-width: 100vw;
  }
  .chapter-list-menu{
    position: fixed;
    z-index: 10;
    max-width: 100vw;
    overflow-x: auto;
    background: #FFFFFF;
    padding-left: 0;
    margin-left: 0;
    ul{
      li.list-group-item{
        border:none;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.02em;
        width: 120px;
        padding:15px 10px;
        a{
          display: flex;
          span{
            display: inline-block;
            text-overflow: ellipsis;
            width: 76px;
            /* Needed to make it work */
            overflow: hidden;
            white-space: nowrap;
          }
          i{
            font-size: 14px;
            line-height: 18px;
          }
        }

        &.active{
          background-color: transparent;
          border: none;
          color:#000000;
          font-weight: 700;
        }

        &.disabled{
          opacity: 0.6;
        }
      }
    }
  }
}
