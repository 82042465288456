/* You can add global styles to this file, and also import other style files */
// @import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,600,700");
// Global vendors
@import 'variables';
@import "~bootstrap/dist/css/bootstrap.css";
// => Material
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

@import "~highlight.js/styles/googlecode.css";
// @import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Global fonts
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "../assets/plugins/flaticon/flaticon.css";
@import "../assets/plugins/flaticon2/flaticon.css";
@import "../assets/plugins/keenthemes-icons/font/ki.css";

// Uppy
@import '@uppy/core/dist/style.css';
@import '@uppy/drag-drop/dist/style.css';
@import '@uppy/progress-bar/dist/style.css';
@import '@uppy/informer/dist/style.css';

// Metronic styles (replace these path when using RTL css below)
@import "../assets/sass/style.angular.scss";
@import '~video.js/dist/video-js.css';

@import "~@ng-select/ng-select/themes/default.theme.css";
// ... or
// @import "~@ng-select/ng-select/themes/material.theme.css";

// For RTL, check this documentation https://keenthemes.com/metronic/?page=docs&section=angular-rtl
// @import "./assets/sass/style.angular.rtl.css";

// Default Layout themes
// @import "./assets/sass/themes/layout/header/base/light.scss";
// @import "./assets/sass/themes/layout/header/menu/light.scss";
// @import "./assets/sass/themes/layout/brand/dark.scss";
// @import "./assets/sass/themes/layout/aside/dark.scss";

// Header themes
// .header-base-light {
//     @import "./assets/sass/themes/layout/header/base/light.scss";
// }

// .header-base-dark {
//     @import "./assets/sass/themes/layout/header/base/dark.scss";
// }

// Header Menu themes
// .header-menu-light {
//     @import "./assets/sass/themes/layout/header/menu/light.scss";
// }
// .header-menu-dark {
//     @import "./assets/sass/themes/layout/header/menu/dark.scss";
// }

// Brand themes
// .brand-dark {
//   @import "./assets/sass/themes/layout/brand/dark.scss";
// }

// .brand-light {
//   @import "./assets/sass/themes/layout/brand/light.scss";
// }

// Aside themes
// .aside-dark {
//   @import "./assets/sass/themes/layout/aside/dark.scss";
// }

// .aside-light {
//   @import "./assets/sass/themes/layout/aside/light.scss";
// }

// hide bootstrap dropdown arrow globally
@import "mixins";


ng-dropdown-panel {
  z-index: 1060!important;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row>.col, .form-row>[class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.dropdown-toggle::after {
  display: none !important;
}

.angular-bootstrap-table {
  overflow-x: auto;
}

.btn-block {
  display: block;
  width: 100%;
}

.angular-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead th.sortable-active {
    color: $primary !important;
  }
}

.cursor-default {
  cursor: default !important;
}

.progress-modal {
  height: 3px;
  width: 100%;
}

.svg-icon.aside-icon.self-pace {
  svg {
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }
}

.introjs-tooltip{
  border-radius:0;
}
.introjs-tooltip-header{
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  .introjs-tooltip-title{
    font-family: 'HK Grotesk', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }
}
.introjs-tooltiptext{
  padding:16px;
  font-family: 'HK Grotesk',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: #000000;
  h3{
    font-family: 'HK Grotesk',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
  }
}

.activity-button {
  display: flex;
  padding: 12px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 2px;
  border: 1px dashed var(--pelangi-handayani-biru-langit, #3887C7);
  background: rgba(56, 135, 199, 0.10);
  overflow: hidden;
  color: var(--pelangi-handayani-biru-langit, #3887C7);
  leading-trim: both;
  text-edge: cap;
  text-overflow: ellipsis;

  /* Text/s */
  font-family: HK Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
  letter-spacing: -0.24px;
}

.modal-settings {
  .modal-dialog {
    max-width: 600px;
    margin-top: 80px;
    .modal-content {
      border-radius: 12px;
      background: #FFF;
    }
  }
}

.note-editor.note-frame {
  width: 100%;
}

.modal-confirmation {
  .modal-dialog {
    max-width: 360px;
    margin-top: 80px;
    .modal-content {
      background: #FFF;
    }
  }
}

.modal-certificate {
  .modal-dialog {
    max-width: 400px;
    margin-top: 80px;
    .modal-content {
      background: #FFF;
    }
  }
}

.form-divider {
  display: block;
  height: 1px;
  width: 100%;
  background-color: var(--border-border, #EBEDF3);
}

.required-label::after {
  content: ' *';
  color: red;
}

.custom-switch {
  padding-left: 0;
}

.ng-select-container {
  border-radius: 0!important;
  border: 1px solid #E4E6EF!important;
}

/* Adjust the label's position to the right */
.custom-control-label.xl {
  position: absolute;
  right: 10px;
  left: auto;
}

/* Optional: Customize the appearance of the switch when it's on */
.custom-control-input:checked ~ .custom-control-label.xl::before {
  background-color: #FFFFFF; /* Green color */
  border-color: #EBEDF3; /* Green color */
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #03CCB8;
  transform: translateX(16px);
}

/* Optional: Customize the appearance of the switch when it's off */
.custom-control-label.xl::before {
  background-color: #F3F6F9; /* Red color */
  border-color: #F3F6F9; /* Red color */
  width: 40px!important;
  height: 22px;
  border-radius: 9999px!important;
}

.custom-control-label.xl::after {
  width: 14px!important;
  height: 14px!important;
  left: -24px;
  top: 8px;
}

.introjs-bullets {
  text-align: left;
  ul li {
    margin: 0 4px;

    a {
      width: 8px;
      height: 8px;
      border-radius: 0;

      &.active {
        background: #000;
        width: 8px;
      }

      &:focus, &:hover {
        width: 8px;
      }
    }
  }
}
.introjs-tooltipbuttons{
  border-top:none;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  .introjs-button{
    background-color: #000000;
    font-family: 'HK Grotesk',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    padding: 11.5px 21px;
    border-radius: 0;
    border:none;
    text-shadow: none;
    &:focus{
      box-shadow: none;
    }
  }
  .introjs-prevbutton{
    visibility: hidden;
  }
  &:after{
    content: none;
  }
}
.modal-tooltip .modal-dialog{
  max-width: 673px;
  @media all and (max-width: 500px){
    justify-content: center;
    display: flex;
    align-items: center;
    margin-top: 25%;
    max-width: 100%;
  }
}
.modal-tooltip .modal-content{
  width: 673px;
  padding:32px;
  @media all and (max-width: 500px){
    width: 90%;
    padding: 16px;
    padding-top: 0;
  }
}
.yt-player-tooltip{
  width: 100%;
  height: 361px;
}
.on-boarding-title-tooltip{
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-top: 24px;
}
.on-boarding-desc-tooltip{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-top: 24px;
}
.btn-start-tour{
  margin-top: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  padding: 11px 22.5px;
  &:disabled{
    background-color: #B5B5C3;
    border-color: #B5B5C3;
    color: rgba(0, 0, 0, 0.38);
  }
}
.block-ui-wrapper{
  background: rgba(0, 0, 0, 0.05) !important;
}
.block-ui-wrapper.active{
  display: flex!important;
  justify-content: center;
  align-items: center;
}
@media all and (max-width: 500px){
  .yt-player-tooltip{
    width: calc(100% + 32px);
    height: 184px;
    margin-left: -16px;
  }
  .on-boarding-title-tooltip{
    margin-top: 16px;
  }
  .on-boarding-desc-tooltip{
    margin-top: 8px;
  }
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link{
  background-color: #000000;
}
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-text{
  color: #FFFFFF;
}
.aside-menu .menu-nav .menu-section{
  padding: 0;
  margin-top:0;
}
@media (max-width: 991.98px){
  .aside-secondary-enabled .content{
    padding-top: 0;
  }
}
@media screen and (max-width: $ipad){
  @include chapter-list-menu;
}
@media screen and (width: 810px){
  @include chapter-list-menu;
}

@media screen and (max-width: $ipad){
  .subheader{
    padding:0 !important;
  }
  .main-container{
    padding:0;
  }
}
.plr-30{
  @media screen and (max-width: $ipad) {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.plr-15{
  @media screen and (max-width: $ipad) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.modal-complete-profile{
  .modal-dialog{
    @media screen and (min-width: $mobile){
      max-width: 70vw;
    }
    .modal-content{
      width:100%;
      display: flex;
      flex-direction: column;
      @media screen and (min-width: $mobile){
        flex-direction: row;
      }
      .left-bar{
        background-color: #FF6121;
        width: 40.8%;
        padding:50px 48px;
        background-image: url('../assets/media/bg/bg-set-profile-desktop.png');
        background-size: contain;
        background-position: bottom left;
        background-repeat: no-repeat;
        .logo{
          width: 40px;
          left: 40px;
          margin-bottom: 20px;
        }
        h1{
          font-style: normal;
          font-weight: bold;
          font-size: 28px;
          line-height: 36px;
          color: #FFFFFF;
          margin-bottom:24px;
        }
        h2{
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.02em;
          color: #FFFFFF;

        }
        @media screen and (max-width: $ipad) {
          width: 100%;
          padding:23px 16px;
          background-image: url('../assets/media/bg/bg-set-profile-mobile.png');
          background-size: 65%;
          background-position: bottom right;
          background-repeat: no-repeat;
          h1{
            font-size: 18px;
            line-height: 23px;
          }
          h2{
            font-size: 14px;
            line-height: 18px;
            margin-bottom:114px;
          }
        }
      }
      .right-bar{
        padding:40px 93px;
        width:59.2%;
        @media screen and (max-width: $ipad) {
          width: 100%;
          padding:40px 16px;
        }

        sup{
          font-size: 14px;
          top: -0.2em;
        }
      }

      p.info {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.02em;

        /* Grey / Idle */
        color: #B5B5C3;
      }

      .col-dua {
        .form-group {
          &:first-child {
            flex: 1;
            margin-right: 16px;
          }
          &:last-child {
            width: calc(50% - 16px);
          }
        }
      }
    }
  }
  .image-input {
    border-radius: 100%;
    .image-input-wrapper{
      border-radius: 100%;
      width:66.6px;
      height:66.6px;
    }
    &.image-input-outline{
      .image-input-wrapper{
        box-shadow: none;
      }
    }
    [data-action=change]{
      top:unset;
      right: 0;
      bottom: -10px;
      img{
        width:32px;
        height:32px;
      }
    }
  }
}
.js-step {
  display: none;
}

.js-step-active {
  display: block;
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #dc3545;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

.modal-upload-payment{
  .modal-body {
    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
      text-align: center;
      color: #000000;
      margin-bottom: 16px;
    }

    .header-row {
      display: flex;
      justify-content: space-between;
      .header-cell {
        &.f1 {
          flex: 1;
        }
        &.f0 {
          flex: 0;
        }

        p {
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.02em;
          color: #000000;
          margin-bottom: 8px;
        }

        h2 {
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;

          color: #000000;
        }
      }
    }
  }

  .modal-footer {
    display: block;
    h3 {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }

    .button-wrapper {
      float: right;
      button {
        margin-left: 4px;
      }
    }
  }
}

.uppy-thumbnails{
  display: flex;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;

  .uppy-thumbnail-container{
    width: calc(50% - 5px);
    margin: 10px 5px 0;
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;
    background-color: $gray-100;
    @include border-radius($border-radius);
    overflow: hidden;

    .uppy-thumbnail{
      width: 60px;
      height: 50px;
      overflow: hidden;
      display: flex;
      justify-content: center;

      img{
        height: 100%;
        width: auto;
      }
    }

    .uppy-thumbnail-label{
      font-weight: 400;
      color: $dark-75;
      margin-left: 10px;
      padding-right: 10px;
    }

    .uppy-remove-thumbnail{
      margin: auto 10px auto auto;
      cursor: pointer;

      i {
        font-size: 0.7rem;
        color: $dark-50;
        transition: $transition-link;
      }

      &:hover {
        i {
          color: $primary;
          transition: $transition-link;
        }
      }
    }

    &:nth-child(odd){
      margin-left: 0;
    }

    &:nth-child(even){
      margin-right: 0;
    }

    @include media-breakpoint-down(lg) {
      width: 100%;
      margin: 10px 0 0;
    }
  }
}
.q-text{
  img{
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

sa-icon {
  svg.lightStroke {
    path {
      stroke: #FFF;
    }
  }
  svg.darkStroke {
    path {
      stroke: #000;
    }
  }
}

.main-con-con.quiz-container{
  align-items: flex-start !important;
  margin-left: unset;
  @media all and (max-width: 500px){
    padding:0;
    overflow-x: initial;
    width: 100%;
  }
}

.main-con-con.chapter-container{
  @media all and (max-width: 500px){
    padding:0;
    overflow-x: initial;
    width: 100%;
  }
}

.sa-tab-container {
  display: flex;
  width: 100%;
  padding: 24px 24px 40px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 8px;
  background: var(--surface-background-primary, #FFF);

  .reading-content {
    img {
      max-width: 100%;
    }
  }

  .tabs {
    display: flex;
    width: 100%;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    flex-wrap: wrap;
    border-bottom: 1px solid #C4C4C4;

    .tab {
      display: flex;
      height: 33px;
      padding: 8px 16px;
      align-items: flex-start;
      gap: 10px;
      color: var(--grey-idle, #B5B5C3);

      /* Text/md/reguler */
      font-family: HK Grotesk;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.28px;
      border-bottom: 1.5px solid #FFF;
      cursor: pointer;

      &.active {
        cursor: default;
        border-bottom: 1.5px solid var(--text-primary, #000);
        color: var(--neutral-black, #000);
      }
    }
  }

  .description-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    h3 {
      color: #000;
      leading-trim: both;
      text-edge: cap;
      margin-bottom: 0;
      /* Text/xl */
      font-family: HK Grotesk;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 36px */
      letter-spacing: -0.48px;
    }
    p {
      margin-bottom: 0;
      color: var(--text-primary, #000);
      font-family: HK Grotesk;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }

  .resources-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    width: 100%;

    .resource-card {
      display: flex;
      align-items: center;
      gap: 24px;
      align-self: stretch;

      img {
        height: 40px;
        width: auto;
      }

      .description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        flex: 1 0 0;

        h4 {
          color: #000;
          leading-trim: both;
          text-edge: cap;
          margin-bottom: 0;
          /* Text/lg */
          font-family: HK Grotesk;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 27px */
          letter-spacing: -0.36px;
        }
        p {
          margin-bottom: 0;
          color: var(--text-primary, #000);
          font-family: HK Grotesk;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.28px;
        }
      }

      @media screen and (max-width: $mobile) {
        gap: 16px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

  }

  .divider {
    height: 1px;
    width: 100%;
    background-color: #EBEDF3;
  }

  .info {
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    background: rgba(56, 135, 199, 0.10);
    color: var(--pelangi-handayani-biru-langit, #3887C7);

    /* Text/md/reguler */
    font-family: HK Grotesk;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
    img {
      width: 18px;
      height: 18px;
    }
  }

  .cert-card {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    img {
      width: 96px;
      height: 64px;
      object-fit: cover;
      object-position: center center;
    }

    .cert-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 18px;
      flex: 1 0 0;
      // border-bottom: 1px solid #B5B5C3;

      .cert-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        width: 100%;

        .text-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;

          h4 {
            margin-bottom: 0;
            color: #000;

            /* H2 */
            font-family: HK Grotesk;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          p {
            margin-bottom: 0;
            color: var(--grey-idle, #B5B5C3);

            /* Paragraph / Medium */
            font-family: HK Grotesk;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.28px;
          }
        }
        .btn-container {
          display: flex;
          align-items: flex-start;
          gap: 8px;
        }
      }
    }

    @media screen and (max-width: $mobile) {
      .cert-container {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
      }
    }
  }
}
#kt_students_wrapper{
  width: 100%;
}
.completed-span {
  color: #03ccb8;
  font-weight: bold;
}
.not-completed-span {
  color: #FF1C26;
  font-weight: bold;
}
.start-checked {
  color: orange;
}
@import 'uppy-custom';
